import React from 'react';
import { OrganizationList as OrganizationListComponent } from '@clerk/clerk-react';
import { clerkSignInUpElements, clerkVariables } from '../variables/clerk';

const OrganizationList = () => {
    return (
        <div className="auth-container">
            <OrganizationListComponent
                hidePersonal={true}
                afterSelectOrganizationUrl="/"
                appearance={{
                    elements: clerkSignInUpElements,
                    variables: clerkVariables,
                }}
            />
        </div>
    );
};

export default OrganizationList;
