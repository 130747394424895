import { Dispatch, FunctionComponent, SetStateAction, useState } from 'react';
import FilterButton from './FilterButton';
import React from 'react';
import { FilterConfig } from '../contexts/ConfigContext';
import SortButton from './SortButton';
import { SortOption } from '../types';
import { sortOptions } from '../hooks/useDefaultLibraryContext';

interface FiltersProps {
    filters: FilterConfig[] | undefined;
    appliedFilters: Record<string, string[]>;
    setAppliedFilters: (
        value: React.SetStateAction<Record<string, string[]>>,
    ) => void;
    filterCountRecord: Record<string, Record<string, number>>;
    sortProps?: {
        appliedSortOption: SortOption;
        setAppliedSortOption: Dispatch<SetStateAction<SortOption>>;
    };
}

const Filters: FunctionComponent<FiltersProps> = ({
    filters,
    appliedFilters,
    setAppliedFilters,
    filterCountRecord,
    sortProps,
}) => {
    const initialCheckedFilters = Object.fromEntries(
        Object.entries(filterCountRecord).map(([filter, values]) => [
            filter,
            Object.prototype.hasOwnProperty.call(appliedFilters, filter)
                ? appliedFilters[filter]
                : Object.keys(values),
        ]),
    );

    const [checkedFilters, setCheckedFilters] = useState<
        Record<string, string[]>
    >(initialCheckedFilters);
    const [activeDropdown, setActiveDropdown] = useState<string | null>(null);

    const handleCheckedItemsChange = (
        filterName: string,
        checkedItems: string[],
    ) => {
        setCheckedFilters((prev) => ({
            ...prev,
            [filterName]: checkedItems,
        }));
    };

    const handleToggleActive = (filterName: string | null) => {
        if (filterName !== 'sort' && filterName !== null) {
            const appliedFields = Object.prototype.hasOwnProperty.call(
                appliedFilters,
                filterName,
            )
                ? appliedFilters[filterName]
                : Object.prototype.hasOwnProperty.call(
                        filterCountRecord,
                        filterName,
                    )
                  ? Object.keys(filterCountRecord[filterName])
                  : [];
            setCheckedFilters((prev) => ({
                ...prev,
                [filterName]: appliedFields,
            }));
        }
        setActiveDropdown(activeDropdown === filterName ? null : filterName);
    };
    return (
        <div className="filter-buttons">
            {sortProps && (
                <SortButton
                    showDropdown={activeDropdown == 'sort'}
                    appliedSortOption={sortProps.appliedSortOption}
                    setAppliedSortOption={sortProps.setAppliedSortOption}
                    sortOptions={sortOptions}
                    onToggleActive={handleToggleActive}
                />
            )}
            {filters?.map((filter) => (
                <FilterButton
                    key={filter.name}
                    filterDisplayName={filter.display_name}
                    filterName={filter.name}
                    isActive={activeDropdown === filter.name}
                    checkedItems={checkedFilters[filter.name] || []}
                    onCheckedItemsChange={(checkedItems) =>
                        handleCheckedItemsChange(filter.name, checkedItems)
                    }
                    onToggleActive={handleToggleActive}
                    appliedFilters={appliedFilters}
                    setAppliedFilters={setAppliedFilters}
                    optionsWithCount={
                        Object.prototype.hasOwnProperty.call(
                            filterCountRecord,
                            filter.name,
                        )
                            ? filterCountRecord[filter.name]
                            : {}
                    }
                />
            ))}
        </div>
    );
};

export default Filters;
