import React from 'react';
import MainApp from './pages/MainApp';
import './App.scss';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useDefaultLibraryContext } from './hooks/useDefaultLibraryContext';
import { LibraryContext } from './contexts/LibraryContext';
import { AnalyticsContext } from './contexts/AnalyticsContext';
import { useDefaultAnalyticsContext } from './hooks/useDefaultAnalyticsContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ConfigProvider from './contexts/ConfigProvider';
import useAxiosInterceptor from './hooks/useAxiosInterceptor';
import 'mac-scrollbar/dist/mac-scrollbar.css';
import { useDefaultSpecificationsContext } from './hooks/useDefaultSpecificationsContext';
import { SpecificationsContext } from './contexts/SpecificationsContext';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import { UploadStateProvider } from './contexts/UploadContext';
import OrganizationList from './pages/OrganizationList';
import { syncActiveOrganizationFromURLToSession } from './utils/syncActiveOrganizationFromURLToSession';
import { SignedIn, SignedOut } from '@clerk/clerk-react';

const App = () => {
    useAxiosInterceptor();

    const defaultLibraryContext = useDefaultLibraryContext();
    const defaultAnalyticsContext = useDefaultAnalyticsContext();
    const defaultSpecificationsContext = useDefaultSpecificationsContext();

    const queryClient = new QueryClient();
    syncActiveOrganizationFromURLToSession();

    return (
        <QueryClientProvider client={queryClient}>
            <ConfigProvider>
                <Routes>
                    <Route
                        path="*"
                        element={
                            <LibraryContext.Provider
                                value={defaultLibraryContext}
                            >
                                <AnalyticsContext.Provider
                                    value={defaultAnalyticsContext}
                                >
                                    <SpecificationsContext.Provider
                                        value={defaultSpecificationsContext}
                                    >
                                        <UploadStateProvider>
                                            <MainApp />
                                        </UploadStateProvider>
                                    </SpecificationsContext.Provider>
                                </AnalyticsContext.Provider>
                            </LibraryContext.Provider>
                        }
                    />
                    <Route path="/sign-in/*" element={<SignIn />} />
                    <Route
                        path="/orgs"
                        element={
                            <>
                                <SignedIn>
                                    <OrganizationList />
                                </SignedIn>
                                <SignedOut>
                                    <Navigate to="/" />
                                </SignedOut>
                            </>
                        }
                    />
                    <Route path="/sign-up/*" element={<SignUp />} />
                    {/* <Route path="*" element={<Navigate to={'/orgs'} />} /> */}
                </Routes>
            </ConfigProvider>
        </QueryClientProvider>
    );
};

export default App;
