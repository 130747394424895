import React from 'react';

type StatusIndicatorProps = {
    decision: 'Fail' | 'Pass' | 'Warning' | string;
    isBig: boolean;
};

const StatusIndicator: React.FC<StatusIndicatorProps> = ({
    decision,
    isBig,
}) => {
    let decisionClass = isBig ? 'status-indicator-big ' : 'status-indicator ';
    let decisionText = '';
    if (decision === 'Rejected') {
        decisionClass += 'fail';
        decisionText = 'Rejected';
    } else if (decision === 'AI Approved') {
        decisionClass += 'pass';
        decisionText = 'AI Approved';
    } else if (decision === 'Refer') {
        decisionClass += 'warning';
        decisionText = 'Refer';
    }

    if (!decisionText) return null;

    return (
        <div className="status-indicator-container">
            <div className={decisionClass}>{decisionText}</div>
        </div>
    );
};

export default StatusIndicator;
