import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SpecItem, SpecItemDetailed } from '../types';
import { useSpecificationsContext } from '../contexts/SpecificationsContext';
import SpecCard from './SpecCard';
import AddSpecCard from './AddSpecCard';

const SpecGallery: React.FC = () => {
    const navigate = useNavigate();
    const {
        state: { specificationItems },
    } = useSpecificationsContext();

    const handleSelect = (item: SpecItem) => {
        navigate(`/specifications/${item.id}`);
    };

    return (
        <div className="results-table-grid">
            <AddSpecCard
                onSelect={() => {
                    navigate('/specifications/add');
                }}
            />
            {specificationItems.length > 0 ? (
                specificationItems.map((item) => (
                    <SpecCard
                        key={item.id}
                        item={item}
                        onSelect={(item) => {
                            handleSelect(item);
                        }}
                    />
                ))
            ) : (
                <p className="no-results-message">No results for this filter</p>
            )}
        </div>
    );
};

export default SpecGallery;
