import React, { FunctionComponent } from 'react';
import { useConfigContext } from '../contexts/ConfigContext';
import Filters from './Filters';
import { useLibraryContext } from '../contexts/LibraryContext';
import SearchContainer from './SearchContainer';
import { isMobile } from 'react-device-detect';

const LibraryControlContainer: FunctionComponent = () => {
    const configData = useConfigContext();
    const libraryContext = useLibraryContext();

    const filterProps = {
        filters: configData?.pages.library.gallery.filters,
        appliedFilters: libraryContext.state.appliedFilters,
        setAppliedFilters: libraryContext.setters.setAppliedFilters,
        filterCountRecord: libraryContext.state.filterCountRecord,
        sortProps: {
            appliedSortOption: libraryContext.state.appliedSortOption,
            setAppliedSortOption: libraryContext.setters.setAppliedSortOption,
        },
    };

    return (
        <>
            <div className="filter-controls">
                <h2>Labels</h2>
                {!isMobile && <Filters {...filterProps} />}
                <SearchContainer
                    appliedSearchString={libraryContext.state.searchString}
                    setAppliedSearchString={
                        libraryContext.setters.setSearchString
                    }
                />
            </div>
            {isMobile && (
                <div className="filter-controls mobile">
                    <Filters {...filterProps} />
                </div>
            )}
        </>
    );
};

export default LibraryControlContainer;
