import { createContext, useContext } from 'react';
import { SpecItemDetailed } from '../types';

// Your existing state interface
export interface SpecificationsState {
    currentPage: number;
    totalPages: number;
    prevPage: number | null;
    nextPage: number | null;
    numItems: number;
    // appliedSortOption: SortOption;
    // appliedFilters: Record<string, string[]>;
    // filterCountRecord: Record<string, Record<string, number>>;
    specificationItems: SpecItemDetailed[];
    searchString: string;
    isLoading: boolean;
}

// New interface for setters
export interface SpecificationsSetters {
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    setTotalPages: React.Dispatch<React.SetStateAction<number>>;
    setPrevPage: React.Dispatch<React.SetStateAction<number | null>>;
    setNextPage: React.Dispatch<React.SetStateAction<number | null>>;
    setNumItems: React.Dispatch<React.SetStateAction<number>>;
    // setAppliedSortOption: React.Dispatch<React.SetStateAction<SortOption>>;
    // setAppliedFilters: React.Dispatch<
    //     React.SetStateAction<Record<string, string[]>>
    // >;
    // setFilterCountRecord: React.Dispatch<
    //     React.SetStateAction<Record<string, Record<string, number>>>
    // >;
    setSpecificationItems: React.Dispatch<
        React.SetStateAction<SpecItemDetailed[]>
    >;
    setSearchString: React.Dispatch<React.SetStateAction<string>>;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

// Update the context to include both state and setters
export interface SpecificationsContextType {
    state: SpecificationsState;
    setters: SpecificationsSetters;
}

export const SpecificationsContext =
    createContext<SpecificationsContextType | null>(null);

export function useSpecificationsContext() {
    const specificationsContext = useContext(SpecificationsContext);

    if (specificationsContext === null) {
        throw new Error(
            'useSpecificationsContext must be used with a SpecificationsContext',
        );
    }

    return specificationsContext;
}
