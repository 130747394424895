import { createContext, useContext } from 'react';
import { RuleResult } from '../types';

export interface ComplianceEngineConfig {
    general: GeneralSettings;
    pages: PagesConfig;
}

export interface GeneralSettings {
    colour: string;
    logo: string;
    title: string;
}

export interface DropdownField {
    name: string;
    display_name: string;
    choices: string[];
    default: string | null;
    multipick: boolean;
    conditions: Record<string, unknown>;
}

export interface FilterConfig {
    name: string;
    display_name: string;
}

export interface TextField {
    name: string;
    display_name: string;
    conditions: Record<string, unknown>;
}

export interface UserInputConfig {
    dropdowns: DropdownField[];
    text_fields: TextField[];
}

export interface PageUploadConfig {
    tab_display_name: string;
    page_title: string;
    text: string;
    user_input: UserInputConfig;
}

export interface GalleryFilterConfig {
    names: string[];
    display_names: string[];
}

export interface GalleryAssetInformationConfig {
    primary_key: string;
    table: Record<string, string[]>;
}

export interface AssetDetailsTableColumnConfig {
    name: keyof RuleResult;
    display_name: string;
}

export interface PageLibraryConfig {
    tab_display_name: string;
    page_title: string;
    gallery: {
        filters: FilterConfig[];
        asset_information: GalleryAssetInformationConfig;
    };
    asset_details: {
        title_key: string;
        table: AssetDetailsTableColumnConfig[];
    };
}

export interface PageAnalyticsConfig {
    tab_display_name: string;
    page_title: string;
    filters: FilterConfig[];
}

export interface PagesConfig {
    upload: PageUploadConfig;
    library: PageLibraryConfig;
    analytics: PageAnalyticsConfig;
}

export interface ComplianceEngineConfig {
    general: GeneralSettings;
    pages: PagesConfig;
}

export const ConfigContext = createContext<
    ComplianceEngineConfig | null | undefined
>(undefined);

export function useConfigContext() {
    const config = useContext(ConfigContext);

    if (config === undefined) {
        throw new Error('useConfigContext must be used with a ConfigContext');
    }

    return config;
}
