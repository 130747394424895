import React, { useMemo, useState } from 'react';
import {
    CheckResult,
    CheckType,
    SpecStringContent,
    SpecStringListContent,
    SpecStringListResults,
    SpecStringResults,
    TokenMatchType,
} from '../types';
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

interface SpecCheckDetailsTextProps {
    checkResult: CheckResult;
}

const SpecCheckDetailsTextNew: React.FC<SpecCheckDetailsTextProps> = ({
    checkResult,
}) => {
    function getMatchSequences() {
        switch (checkResult.check_type) {
            case CheckType.SPEC_STRING:
                return [
                    (checkResult.output_data as SpecStringResults)
                        .match_sequence,
                ];
            case CheckType.SPEC_STRING_LIST:
                return (checkResult.output_data as SpecStringListResults)
                    .match_sequences;
            default:
                return [];
        }
    }
    function getSizeReq() {
        switch (checkResult.check_type) {
            case CheckType.SPEC_STRING:
                return (checkResult.input_data as SpecStringContent).text_size;
            case CheckType.SPEC_STRING_LIST:
                return (checkResult.input_data as SpecStringListContent)
                    .text_size;
            default:
                return null;
        }
    }
    const sizeReq = getSizeReq();
    function getTextSize() {
        switch (checkResult.check_type) {
            case CheckType.SPEC_STRING:
                return (checkResult.output_data as SpecStringResults).text_size;
            case CheckType.SPEC_STRING_LIST:
                return (checkResult.output_data as SpecStringListResults)
                    .text_size;
            default:
                return null;
        }
    }
    const textSize = getTextSize();
    const containsArabic = (text: string | undefined): boolean =>
        text ? /[\u0600-\u06FF]/.test(text) : false;

    // const isArabic = useMemo(() => {
    //     let result = false;
    //     switch (checkResult.check_type) {
    //         case CheckType.SPEC_STRING:
    //             const { match_sequence } =
    //                 checkResult.output_data as SpecStringResults;
    //             for (const {
    //                 spec_token,
    //                 label_token,
    //             } of match_sequence.checked_tokens) {
    //                 if (
    //                     containsArabic(spec_token?.text) ||
    //                     containsArabic(label_token?.text)
    //                 ) {
    //                     result = true;
    //                     break;
    //                 }
    //                 if (result) break;
    //             }
    //             break;
    //         case CheckType.SPEC_STRING_LIST:
    //             const { match_sequences } =
    //                 checkResult.output_data as SpecStringListResults;
    //             for (const sequence of match_sequences) {
    //                 for (const {
    //                     spec_token,
    //                     label_token,
    //                 } of sequence.checked_tokens) {
    //                     if (
    //                         containsArabic(spec_token?.text) ||
    //                         containsArabic(label_token?.text)
    //                     ) {
    //                         result = true;
    //                         break;
    //                     }
    //                     if (result) break;
    //                 }
    //                 if (result) break;
    //             }
    //             break;
    //     }
    //     return result;
    // }, [checkResult]);

    const textSizeIsValid =
        sizeReq &&
        textSize &&
        (!sizeReq.min || textSize >= sizeReq.min) &&
        (!sizeReq.max || textSize <= sizeReq.max);

    return (
        <table className="spec-check-details-text-changes-new">
            <thead>
                <tr></tr>
            </thead>
            <tbody>
                <tr>
                    <td>Text</td>
                    <td>
                        {getMatchSequences().map((matchSequence, i) => {
                            const isArabic = () => {
                                let result = false;

                                for (const {
                                    spec_token,
                                    label_token,
                                } of matchSequence.checked_tokens) {
                                    if (
                                        containsArabic(spec_token?.text) ||
                                        containsArabic(label_token?.text)
                                    ) {
                                        result = true;
                                        break;
                                    }
                                    if (result) break;
                                }
                                return result;
                            };

                            return (
                                <p
                                    key={i}
                                    style={{
                                        ...(isArabic() && {
                                            direction: 'rtl',
                                            textAlign: 'right',
                                        }),
                                    }}
                                >
                                    {matchSequence.checked_tokens.map(
                                        (checkedToken, j) => {
                                            if (
                                                checkedToken.token_match_type ===
                                                TokenMatchType.FULL_MATCH
                                            ) {
                                                return (
                                                    <span key={j}>
                                                        {
                                                            checkedToken
                                                                .spec_token
                                                                ?.text
                                                        }
                                                    </span>
                                                );
                                            } else {
                                                return (
                                                    <>
                                                        <Tooltip
                                                            title={
                                                                <p
                                                                    style={{
                                                                        margin: '0px',
                                                                        fontSize:
                                                                            '1.4em',
                                                                    }}
                                                                >
                                                                    {`Remove "${checkedToken.label_token?.text}"`}
                                                                </p>
                                                            }
                                                            placement="top"
                                                            arrow
                                                        >
                                                            <span
                                                                key={`${j}.1`}
                                                                className="red"
                                                            >
                                                                {
                                                                    checkedToken
                                                                        .label_token
                                                                        ?.text
                                                                }
                                                            </span>
                                                        </Tooltip>
                                                        <Tooltip
                                                            title={
                                                                <p
                                                                    style={{
                                                                        margin: '0px',
                                                                        fontSize:
                                                                            '1.4em',
                                                                    }}
                                                                >
                                                                    {`Add "${checkedToken.spec_token?.text}"`}
                                                                </p>
                                                            }
                                                            placement="top"
                                                            arrow={true}
                                                        >
                                                            <span
                                                                key={`${j}.2`}
                                                                className="green"
                                                            >
                                                                {
                                                                    checkedToken
                                                                        .spec_token
                                                                        ?.text
                                                                }
                                                            </span>
                                                        </Tooltip>
                                                    </>
                                                );
                                            }
                                        },
                                    )}
                                </p>
                            );
                        })}
                    </td>
                </tr>
                {sizeReq && textSize && (
                    <tr>
                        <td>Size</td>
                        <td className="size">
                            {/* {sizeReq.min && (
                                <span>{`${sizeReq.min.toFixed(2)} mm`}</span>
                            )}
                            {sizeReq.min && (
                                <span>
                                    {textSize >= sizeReq.min ? ' ≤ ' : '  '}
                                </span>
                            )} */}
                            <span>{`${textSize.toFixed(2)} mm`}</span>
                            <FontAwesomeIcon
                                icon={textSizeIsValid ? faCheck : faXmark}
                                color={textSizeIsValid ? '#5CB85C' : 'red'}
                                style={{ fontSize: '1.4em' }}
                            />
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
};

export default SpecCheckDetailsTextNew;
