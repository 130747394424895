import React, { useEffect, useState } from 'react';
import { LabelCheckResult } from '../types';
import StatusIndicator from './StatusIndicator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHashtag } from '@fortawesome/free-solid-svg-icons';
import { faUser, faCalendar } from '@fortawesome/free-regular-svg-icons';
import { formatDate } from '../utils/timeUtils';
import { fetchUserDetails } from '../utils/backendServices';
// import useNumberItems from '../hooks/useNumberItems';

interface AssetCardProps {
    item: LabelCheckResult;
    imageUrl: string;
    onSelect: (item: LabelCheckResult) => void;
    isSelected: boolean;
}

const AssetCard: React.FC<AssetCardProps> = ({
    item,
    imageUrl,
    onSelect,
    isSelected,
}) => {
    const [userName, setUserName] = useState<string | null>(null);
    useEffect(() => {
        fetchUserDetails(item.user_id)
            .then((userDetails) => {
                setUserName(
                    `${userDetails.first_name} ${userDetails.last_name}`,
                );
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);
    return (
        <div
            className={`rule-result-card ${isSelected ? 'selected' : ''}`}
            onClick={() => onSelect(item)}
        >
            <div className="main-image-container">
                <img
                    className="main-image"
                    src={imageUrl}
                    alt={`Thumbnail for ${item.user_input.asset_name}`}
                />
            </div>
            <div className="info-section">
                <div className="upper-container">
                    <p className="project-name">{item.user_input.asset_name}</p>
                    <StatusIndicator
                        decision={item.approval_status}
                        isBig={false}
                    />
                </div>

                {/* <div className="summary-table">
                        <tr>
                            <td>Country:</td>
                            <td>{item.user_input.country}</td>
                        </tr>
                        <tr>
                            <td>Rules flagged:</td>
                            <td>
                                {item.outcome.rules_flagged}/
                                {item.outcome.rules_passed +
                                    item.outcome.rules_flagged}
                            </td>
                        </tr>
                    </div> */}
                <div className="lower-container">
                    <div className="icon-value-container">
                        <FontAwesomeIcon icon={faCalendar} />
                        <p>{formatDate(item.run_info.timestamp)}</p>
                    </div>
                    {userName && (
                        <div className="icon-value-container">
                            <FontAwesomeIcon icon={faUser} />
                            <p>{userName}</p>
                        </div>
                    )}
                    {/* <div className="icon-value-container">
                        <FontAwesomeIcon icon={faHashtag} />
                        <p>{1}</p>
                    </div> */}
                </div>

                {/* <p className="date">
                    {formatDate(item.run_info.start_time)} by {item.user.name}
                </p>
                <div className="right-side">
                    {/* <p className="id">ID: {item.id}</p> */}
            </div>
        </div>
    );
};

export default AssetCard;
