import { useState } from 'react';
import { LibraryContextType } from '../contexts/LibraryContext';
import { LabelCheckResult, SortOption } from '../types';
import { getNumberItems } from './useNumberItems';

export const sortOptions: SortOption[] = [
    {
        label: 'Newest first',
        variable: 'date',
        sortOrder: 'descending',
    },
    {
        label: 'Oldest first',
        variable: 'date',
        sortOrder: 'ascending',
    },
    // {
    //     label: 'Industry: A to Z',
    //     variable: 'industry',
    //     sortOrder: 'ascending',
    // },
    // {
    //     label: 'Industry: Z to A',
    //     variable: 'industry',
    //     sortOrder: 'descending',
    // },
    // {
    //     label: 'Country: A to Z',
    //     variable: 'country',
    //     sortOrder: 'ascending',
    // },
    // {
    //     label: 'Country: Z to A',
    //     variable: 'country',
    //     sortOrder: 'descending',
    // },
    // {
    //     label: 'User: A to Z',
    //     variable: 'user',
    //     sortOrder: 'ascending',
    // },
    // {
    //     label: 'User: Z to A',
    //     variable: 'user',
    //     sortOrder: 'descending',
    // },
];

export function useDefaultLibraryContext(): LibraryContextType {
    const [complianceTableState, setComplianceTableState] = useState<
        LabelCheckResult[]
    >([]);
    const [appliedFilters, setAppliedFilters] = useState<
        Record<string, string[]>
    >({});
    const [searchString, setSearchString] = useState('');
    const [numItems, setNumItems] = useState<number>(getNumberItems());
    const [prevPage, setPrevPage] = useState<number | null>(null);
    const [nextPage, setNextPage] = useState<number | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [filterCountRecord, setFilterCountRecord] = useState<
        Record<string, Record<string, number>>
    >({});
    const [imageUrls, setImageUrls] = useState<{ [id: string]: string }>({});
    const [appliedSortOption, setAppliedSortOption] = useState<SortOption>(
        sortOptions[0],
    );
    const [libraryIsLoading, setLibraryIsLoading] = useState<boolean>(true);

    const state = {
        currentPage,
        totalPages,
        prevPage,
        nextPage,
        numItems,
        appliedSortOption,
        sortOptions,
        appliedFilters,
        filterCountRecord,
        complianceTableState,
        imageUrls,
        searchString,
        libraryIsLoading,
    };

    const setters = {
        setCurrentPage,
        setTotalPages,
        setPrevPage,
        setNextPage,
        setNumItems,
        setAppliedSortOption,
        setAppliedFilters,
        setFilterCountRecord,
        setComplianceTableState,
        setImageUrls,
        setSearchString,
        setLibraryIsLoading,
    };

    return { state, setters };
}
