import React, { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronUp,
    faChevronDown,
    faCheck,
    faXmark,
} from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import './FilterButton.scss';
import arraysEqual from './arraysEqual';
import { isMobile } from 'react-device-detect';

interface FilterButtonProps {
    filterDisplayName: string;
    filterName: string;
    isActive: boolean;
    onToggleActive: (filterDisplayName: string | null) => void;
    checkedItems: string[];
    onCheckedItemsChange?: (checkedItems: string[]) => void;
    appliedFilters: Record<string, string[]>;
    setAppliedFilters: (
        value: React.SetStateAction<Record<string, string[]>>,
    ) => void;
    optionsWithCount: Record<string, number>;
}

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

const FilterButton: FunctionComponent<FilterButtonProps> = ({
    filterDisplayName,
    filterName,
    isActive,
    onToggleActive,
    checkedItems,
    onCheckedItemsChange,
    appliedFilters,
    setAppliedFilters,
    optionsWithCount,
}) => {
    const filterIsApplied: boolean = Object.prototype.hasOwnProperty.call(
        appliedFilters,
        filterName,
    );

    const appliedOptions: string[] = Object.prototype.hasOwnProperty.call(
        appliedFilters,
        filterName,
    )
        ? appliedFilters[filterName]
        : Object.keys(optionsWithCount);

    const resultCount: number = checkedItems.reduce(
        (acc, checkedItem) => acc + (optionsWithCount[checkedItem] || 0),
        0,
    );

    const handleButtonClick = () => {
        onToggleActive(filterName);
    };

    const handleApplyButtonClick = () => {
        setAppliedFilters((prev) => ({
            ...prev,
            [filterName]: checkedItems,
        }));

        onToggleActive(filterName);
    };

    const handleCheckboxChange = (option: string, isChecked: boolean) => {
        if (onCheckedItemsChange) {
            if (isChecked) {
                onCheckedItemsChange([...checkedItems, option]);
            } else {
                onCheckedItemsChange(
                    checkedItems.filter((item) => item !== option),
                );
            }
        }
    };

    const handleSelectAll = () => {
        if (onCheckedItemsChange) {
            onCheckedItemsChange(Object.keys(optionsWithCount));
        }
    };
    const handleUnselectAll = () => {
        if (onCheckedItemsChange) {
            onCheckedItemsChange([]);
        }
    };

    const handleIconClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (!filterIsApplied) {
            onToggleActive(filterName);
        } else {
            if (onCheckedItemsChange) {
                onToggleActive(null);
                setAppliedFilters((prev) => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { [filterName]: omitKey, ...newFilters } = prev;
                    return newFilters;
                });
            }
        }
    };
    // useEffect(() => {
    //     if (shouldResetFilter) {
    //         if (handleApply) {
    //             handleApply();
    //             setShouldResetFilter(false);
    //         }
    //     }
    // }, [shouldResetFilter]);

    return (
        <div className={'filter-button-container'}>
            <div
                className={`filter-button ${
                    filterIsApplied || isActive ? 'applied' : ''
                }`}
                onClick={handleButtonClick}
            >
                <div className="filter-title">{filterDisplayName}</div>
                <FontAwesomeIcon
                    icon={
                        filterIsApplied
                            ? faXmark
                            : isActive
                              ? faChevronDown
                              : faChevronUp
                    }
                    onClick={handleIconClick}
                />
            </div>
            {isActive && Object.keys(optionsWithCount).length > 0 && (
                <div className={`filter-dropdown ${isMobile ? 'mobile' : ''}`}>
                    <div className="select-unselect-container">
                        <div
                            className="select-button"
                            onClick={handleSelectAll}
                        >
                            Select all
                        </div>
                        <div className="dot-seperator">•</div>
                        <div
                            className="select-button"
                            onClick={handleUnselectAll}
                        >
                            Unselect all
                        </div>
                        <div
                            className="escape-button"
                            onClick={handleButtonClick}
                        >
                            <FontAwesomeIcon icon={faXmark} />
                        </div>
                    </div>

                    {/* <div className="checkbox-label"> */}
                    {/* <HiddenCheckbox
              checked={isAllSelected}
              onChange={(e) => handleAllCheckedChange(e.target.checked)}
            /> */}
                    {/* <div className='' checked={isAllSelected}>
              <FontAwesomeIcon icon={faCheck} className="fa-check" />
            </StyledCheckbox> */}
                    {/* All */}
                    {/* </div> */}
                    {Object.entries(optionsWithCount).map(
                        ([option, count], index) => (
                            <label className="checkbox-label" key={index}>
                                <HiddenCheckbox
                                    checked={checkedItems.includes(option)}
                                    onChange={(e) =>
                                        handleCheckboxChange(
                                            option,
                                            e.target.checked,
                                        )
                                    }
                                />
                                <div
                                    className={`checkbox ${
                                        checkedItems.includes(option)
                                            ? 'checked'
                                            : ''
                                    }`}
                                >
                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        className="fa-check"
                                    />
                                </div>
                                {/* <StyledCheckbox
                checked={checkedItems.includes(option)}
              ></StyledCheckbox> */}
                                <div className="checkbox-name">{option}</div>
                                <div className="checkbox-count">{count}</div>
                            </label>
                        ),
                    )}
                    {!arraysEqual(appliedOptions, checkedItems) &&
                    checkedItems.length > 0 ? (
                        <div
                            className="apply-button active"
                            onClick={handleApplyButtonClick}
                        >
                            Show {resultCount} assets
                        </div>
                    ) : (
                        <div className="apply-button inactive">
                            Show {resultCount} assets
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default FilterButton;
