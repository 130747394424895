import React, { FunctionComponent, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPhone } from '@fortawesome/free-solid-svg-icons';
import customer_logo from './../algospark-logo-black-full.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import AddModal from './AddModal';
import {
    OrganizationList,
    OrganizationProfile,
    OrganizationSwitcher,
    Protect,
    SignedIn,
    SignedOut,
    SignInButton,
    useOrganization,
    UserButton,
} from '@clerk/clerk-react';
import { clerkVariables } from '../variables/clerk';

interface NavBarProps {
    handleShowUserModal: () => void;
}

const BuildingIcon = () => {
    return (
        <svg
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 4h12M6 4v16M6 4H5m13 0v16m0-16h1m-1 16H6m12 0h1M6 20H5M9 7h1v1H9V7Zm5 0h1v1h-1V7Zm-5 4h1v1H9v-1Zm5 0h1v1h-1v-1Zm-3 4h2a1 1 0 0 1 1 1v4h-4v-4a1 1 0 0 1 1-1Z"
            />
        </svg>
    );
};

const NavBar: FunctionComponent<NavBarProps> = ({ handleShowUserModal }) => {
    const location = useLocation();
    const isLoginPage = location.pathname === '/login';
    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const modalButtonRef = useRef<HTMLButtonElement>(null);

    const isActive = (path: string) => location.pathname === path;

    const navigate = useNavigate();

    const handleNavigation = (path: string) => {
        navigate(path);
    };

    const { organization } = useOrganization();

    return (
        <div className="navbar-wrapper">
            <nav className="App-navbar">
                <div className="nav-title-container">
                    <img
                        src={customer_logo}
                        alt="Brand logo"
                        className="brand-logo"
                    />
                    Label Checker
                </div>
                {!isLoginPage && (
                    <div className="settings-actions">
                        <div style={{ position: 'relative' }}>
                            <button
                                className="nav-button add"
                                onClick={() => setShowAddModal(!showAddModal)}
                                ref={modalButtonRef}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                                {'Add'}
                            </button>
                            {showAddModal && (
                                <AddModal
                                    closeModal={() => setShowAddModal(false)}
                                    modalButtonRef={modalButtonRef}
                                />
                            )}
                        </div>
                        <button
                            onClick={() => {
                                setShowAddModal(false);
                                handleNavigation('/labels');
                            }}
                            className={`nav-button ${
                                isActive('/labels') ? 'selected' : ''
                            }`}
                        >
                            Labels
                        </button>
                        <button
                            onClick={() => {
                                setShowAddModal(false);
                                handleNavigation('/specifications');
                            }}
                            className={`nav-button ${
                                isActive('/specifications') ? 'selected' : ''
                            }`}
                        >
                            Specifications
                        </button>
                        <button
                            className="nav-button"
                            onClick={handleShowUserModal}
                        >
                            <FontAwesomeIcon icon={faPhone} />
                        </button>
                        <SignedOut>
                            <SignInButton>
                                <button
                                    className="nav-button add"
                                    ref={modalButtonRef}
                                >
                                    Sign in
                                </button>
                            </SignInButton>
                        </SignedOut>
                    </div>
                )}
                <SignedIn>
                    <Protect condition={(has) => has({ role: 'org:admin' })}>
                        {organization && (
                            <OrganizationSwitcher
                                hidePersonal
                                appearance={{
                                    variables: clerkVariables,
                                    elements: {
                                        rootBox: {
                                            marginLeft: '15px',
                                        },
                                        avatarBox: {
                                            width: '26px',
                                            height: '26px',
                                        },
                                    },
                                }}
                                organizationProfileProps={{
                                    appearance: {
                                        variables: clerkVariables,
                                    },
                                }}
                            />
                        )}
                    </Protect>

                    {/* <OrganizationSwitcher hidePersonal>
                        <OrganizationSwitcher.OrganizationProfilePage
                            label="Custom Page"
                            url="custom"
                            labelIcon={<BuildingIcon />}
                        >
                            <div>
                                <h1>Custom Organization Profile Page</h1>
                                <p>
                                    This is the custom organization profile page
                                </p>
                            </div>
                        </OrganizationSwitcher.OrganizationProfilePage>

                        <OrganizationSwitcher.OrganizationProfilePage
                            label="Terms"
                            labelIcon={<BuildingIcon />}
                            url="terms"
                        >
                            <OrganizationList hidePersonal />
                        </OrganizationSwitcher.OrganizationProfilePage>
                    </OrganizationSwitcher> */}

                    {/* <OrganizationList hidePersonal /> */}

                    <UserButton
                        appearance={{
                            variables: clerkVariables,
                            elements: {
                                rootBox: {
                                    marginLeft: '15px',
                                },
                                avatarBox: {
                                    width: '26px',
                                    height: '26px',
                                },
                            },
                        }}
                        userProfileProps={{
                            appearance: {
                                variables: clerkVariables,
                            },
                        }}
                    >
                        {/* <UserButton.MenuItems>
                            <Protect
                                condition={(has) => has({ role: 'org:admin' })}
                            >
                                {organization && (
                                    <UserButton.Action
                                        label={`Manage ${organization.name}`}
                                        labelIcon={<BuildingIcon />}
                                        onClick={() => navigate('/test')}
                                    />
                                )}
                            </Protect>

                            <UserButton.Action
                                label="Change Organization"
                                labelIcon={<BuildingIcon />}
                                onClick={() => navigate('/orgs')}
                            />
                        </UserButton.MenuItems> */}
                    </UserButton>
                </SignedIn>
            </nav>
            {/* <OrganizationProfile /> */}
        </div>
    );
};

export default NavBar;
