import React, { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    RuleResultExtended,
    LabelCheckResultDetailed,
    CheckResult,
    SpecItemDetailed,
} from '../types';
import {
    deleteAsset,
    fetchAndSetAnalyticsData,
    fetchItemDetail,
    fetchSpecificationData,
    handleLibraryRequest,
    submitFeedbackForItem,
} from '../utils/backendServices';
import Spinner from './Spinner';
import FeedbackModal from './FeedbackModal';
import DeleteModal from './DeleteModal';
import { useLibraryContext } from '../contexts/LibraryContext';
import { useAnalyticsContext } from '../contexts/AnalyticsContext';
import AssetDetailsBrowser from './AssetDetailsBrowser';
import { isMobile } from 'react-device-detect';
import AssetDetailsMobile from './AssetDetailsMobile';
import { AssetContext } from '../contexts/AssetContext';
import SpecDetailsModal from './SpecDetailsModal';
import ShowcaseModal from './ShowcaseModal';

const AssetDetails: FunctionComponent = () => {
    const libraryContext = useLibraryContext();
    const analyticsContext = useAnalyticsContext();

    const { id } = useParams<{ id: string }>();
    const [labelCheckResult, setLabelCheckResult] =
        useState<LabelCheckResultDetailed | null>(null);
    const [specData, setSpecData] = useState<SpecItemDetailed | null>(null);
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [focussedRule, setFocussedRule] = useState<string | null>(null);
    const [showProductSpecModal, setShowProductSpecModal] =
        useState<boolean>(false);

    const [error, setError] = useState<string | null>(null);

    const [isSubmittingFeedback, setIsSubmittingFeedback] = useState(false);

    // const [isModalVisible, setIsModalVisible] = useState<boolean>(true);
    const [isDeleteModalVisible, setIsDeleteModalVisible] =
        useState<boolean>(false);
    const [selectedRule, setSelectedRule] = useState<CheckResult | null>(null);

    const handleRuleSelected = (rule: CheckResult) => {
        setSelectedRule(rule);
    };

    const handleCloseModal = () => {
        setSelectedRule(null);
    };

    const handleSubmitFeedback = async (
        selectedRule: CheckResult,
        newOuctome: string,
        comment: string,
    ) => {
        if (id) {
            setSelectedRule(null);
            setIsSubmittingFeedback(true);
            const response = await submitFeedbackForItem(
                id,
                selectedRule.display_name,
                {
                    comment: comment,
                    new_outcome: newOuctome,
                },
            );
            // TODO: Needs to be fixed
            // setItem(response.data);
            setIsSubmittingFeedback(false);
            handleLibraryRequest(libraryContext, 1, false).catch((error) => {
                console.log(error);
            });
        }
    };

    const navigate = useNavigate();

    useEffect(() => {
        // This hook is for fetching data
        if (!id) {
            setError('Item not found.');
            return;
        }
        const fetchData = async () => {
            try {
                const response = await fetchItemDetail(id);
                const itemData = response.data;
                setImageUrl(response.image_url[response.data.id]);
                setLabelCheckResult(itemData as LabelCheckResultDetailed);
                fetchSpecificationData(response.data.spec_id)
                    .then((fetchedSpecData) => {
                        setSpecData(fetchedSpecData);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            } catch (error) {
                console.error(error);
                setError('Error fetching item details.');
            }
        };

        fetchData();
    }, [id]);

    const handleDeleteButton = () => {
        setIsDeleteModalVisible(true);
    };
    const handleDeleteConfirmation = () => {
        if (labelCheckResult) {
            setIsDeleteModalVisible(false);
            libraryContext.setters.setLibraryIsLoading(true);
            navigate('/labels');
            deleteAsset(labelCheckResult.id)
                .then(() => {
                    handleLibraryRequest(libraryContext).catch((error) => {
                        console.log(error);
                    });

                    fetchAndSetAnalyticsData(analyticsContext);
                })
                .catch((error) => {
                    console.error('Error deleting asset:', error);
                });
        }
    };

    const closeDeleteModal = () => {
        setIsDeleteModalVisible(false);
    };

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!labelCheckResult || !specData || !imageUrl || isSubmittingFeedback) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <Spinner title="Loading..." />
            </div>
        );
    }

    return (
        <AssetContext.Provider
            value={{
                state: {
                    labelCheckResult,
                    specData,
                    imageUrl,
                    focussedRule,
                    showProductSpecModal,
                },
                setters: { setFocussedRule, setShowProductSpecModal },
            }}
        >
            <div className="detail-container">
                {/* {selectedRule && (
                    <FeedbackModal
                        selectedRule={selectedRule}
                        onSubmit={handleSubmitFeedback}
                        onClose={handleCloseModal}
                    />
                )} */}
                {/* <DeleteModal
                    isActive={isDeleteModalVisible}
                    onClose={closeDeleteModal}
                    onDelete={handleDeleteConfirmation}
                /> */}
                <ShowcaseModal
                    isActive={isDeleteModalVisible || selectedRule !== null}
                    onClose={() => {
                        handleCloseModal();
                        closeDeleteModal();
                    }}
                />
                <SpecDetailsModal
                    itemId={showProductSpecModal ? specData.id : undefined}
                    onClose={() => setShowProductSpecModal(false)}
                />
                <AssetDetailsBrowser
                    handleDeleteButton={handleDeleteButton}
                    handleRuleSelected={handleRuleSelected}
                />
            </div>
        </AssetContext.Provider>
    );
};

export default AssetDetails;
