import React, { useEffect, useState } from 'react';
import Pagination from '../components/Pagination';
import Spinner from '../components/Spinner';
import { useLibraryContext } from '../contexts/LibraryContext';
import LibraryControlContainer from '../components/LibraryControlContainer';
import AssetGallery from '../components/AssetGallery';
import { useSpecificationsContext } from '../contexts/SpecificationsContext';
import SpecificationsControlContainer from '../components/SpecificationsControlContainer';
import SpecGallery from '../components/SpecGallery';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import SpecDetailsModal from '../components/SpecDetailsModal';
import UploadSpecs from './UploadSpecs';
import SpecDetailsModalWrapper from '../components/SpecDetailsModalWrapper';

const Specifications: React.FC = () => {
    const specificationsContext = useSpecificationsContext();

    const navigate = useNavigate();
    const isLoading = specificationsContext.state.isLoading;

    const displayedItems = specificationsContext.state.specificationItems;

    const isPrevPageAvailable = specificationsContext.state.currentPage > 1;
    const isNextPageAvailable =
        specificationsContext.state.currentPage <
        specificationsContext.state.totalPages;

    return (
        <>
            <Routes>
                <Route path="add" element={<UploadSpecs />} />
                <Route
                    path=":itemId"
                    element={
                        <SpecDetailsModalWrapper
                            onClose={() => navigate('/specifications')}
                        />
                    }
                />
            </Routes>
            <div className="wrapper-container">
                <SpecificationsControlContainer />
                {!isLoading ? <SpecGallery /> : <Spinner />}
                {displayedItems.length > 0 && (
                    <Pagination
                        totalPages={specificationsContext.state.totalPages}
                        isPrevPageAvailable={isPrevPageAvailable}
                        isNextPageAvailable={isNextPageAvailable}
                        currentPage={specificationsContext.state.currentPage}
                    />
                )}
            </div>
        </>
    );
};

export default Specifications;
