import React, { useEffect, useState } from 'react';
import { SpecItem } from '../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHashtag } from '@fortawesome/free-solid-svg-icons';
import { faCalendar, faUser } from '@fortawesome/free-regular-svg-icons';
import { fetchUserDetails } from '../utils/backendServices';
import { formatDate } from '../utils/timeUtils';
// import useNumberItems from '../hooks/useNumberItems';

interface SpecCardProps {
    item: SpecItem;
    onSelect: (item: SpecItem) => void;
}

type Category = 'Food' | 'Wine' | 'Beverage';

export function getColorForCategory(category: Category): string {
    const colorMap: { [key in Category]: string } = {
        Food: '89, 68, 253',
        Wine: '255, 87, 51',
        Beverage: '50, 205, 50',
    };

    return colorMap[category];
}

const SpecCard: React.FC<SpecCardProps> = ({ item, onSelect }) => {
    const [userName, setUserName] = useState<string | null>(null);
    useEffect(() => {
        fetchUserDetails(item.user_id)
            .then((userDetails) => {
                setUserName(
                    `${userDetails.first_name} ${userDetails.last_name}`,
                );
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    return (
        <div className="spec-card" onClick={() => onSelect(item)}>
            <div className="upper-container">
                <div className="category-container">
                    <p
                        className="category"
                        style={{
                            color: `rgb(${getColorForCategory(item.category)})`,
                            backgroundColor: `rgba(${getColorForCategory(
                                item.category,
                            )}, 0.2)`,
                        }}
                    >
                        {item.category}
                    </p>
                    {/* <button>
                        <FontAwesomeIcon icon={faEllipsisV} />
                    </button> */}
                </div>
                <p className="spec-name">{item.spec_name}</p>
                {item.spec_notes && (
                    <p className="spec-notes">{item.spec_notes}</p>
                )}
            </div>
            <div className="lower-container">
                <div className="icon-value-container">
                    <FontAwesomeIcon icon={faCalendar} />
                    <p>{formatDate(item.timestamp)}</p>
                </div>
                {userName && (
                    <div className="icon-value-container">
                        <FontAwesomeIcon icon={faUser} />
                        <p>{userName}</p>
                    </div>
                )}
                {/* <div className="icon-value-container">
                    <FontAwesomeIcon icon={faHashtag} />
                    <p>{1}</p>
                </div> */}
            </div>
        </div>
    );
};

export default SpecCard;
