import { useEffect } from 'react';
import axios, { AxiosResponse } from 'axios';
import instance from '../utils/instance';
import timeoutInstance from '../utils/timeoutInstace';

let baseURL = process.env.REACT_APP_BASE_URL || 'http://localhost:8000';
if (baseURL !== 'http://localhost:8000') {
    baseURL += '/api';
}

const useAxiosInterceptor = () => {
    const refreshTokenURL = '/refresh_token';

    useEffect(() => {
        const onFulfilled: (
            value: AxiosResponse<any, any>,
        ) => AxiosResponse<any, any> = (response) => {
            if (response.config.url !== '/config') {
                // setIsAuthenticated(true);
            }
            return response;
        };
        const onRejected: (error: any) => any = async (error) => {
            const originalRequest = error.config;

            // if (error.response.status === 400) {
            //     return setIsAuthenticated(false);
            // }

            // if (error.response.status === 401) {
            //     if (originalRequest.url == refreshTokenURL) {
            //         return setIsAuthenticated(false);
            //     } else {
            //         originalRequest._retry = true;
            //         try {
            //             const response = await instance.get(refreshTokenURL);
            //             if (response.status === 200) {
            //                 return instance(originalRequest);
            //             } else {
            //                 return setIsAuthenticated(false);
            //             }
            //         } catch (refreshError) {
            //             console.error('Token refresh failed', refreshError);
            //             return setIsAuthenticated(false);
            //         }
            //     }
            // }

            return Promise.reject(error);
        };

        const resInterceptor = instance.interceptors.response.use(
            onFulfilled,
            onRejected,
        );
        const resTimeoutInterceptor = timeoutInstance.interceptors.response.use(
            onFulfilled,
            onRejected,
        );

        // Cleanup function
        return () => {
            axios.interceptors.response.eject(resInterceptor);
            axios.interceptors.response.eject(resTimeoutInterceptor);
        };
    }, []); // Only re-run if setContextState changes
};

export default useAxiosInterceptor;
