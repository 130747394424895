import React, { useState, useCallback } from 'react';
import Dropdown from '../components/Dropdown';
import UploadBoxSpecs from '../components/UploadBoxSpecs';
import ModalBase from '../components/ModalBase';
import { useNavigate } from 'react-router-dom';
import ShowcaseModal from '../components/ShowcaseModal';
import InputFieldTitle from '../components/InputFieldTitle';
import { SignedIn, SignedOut, SignInButton } from '@clerk/clerk-react';
import { fetchSpecUploadToken, ingestSpec } from '../utils/backendServices';
import axios from 'axios';
import { Category, FileFormat } from '../types';
import { useUploadContext } from '../contexts/UploadContext';

interface ProductInfo {
    productName: string;
    notes: string;
    category: Category | '';
}

type FieldDefinition = {
    label: string;
    fieldName: keyof ProductInfo;
    required: boolean;
    inputType: 'text' | 'dropdown';
    options?: { value: string; label: string }[]; // For dropdowns
};

const inputFields: FieldDefinition[] = [
    {
        label: 'Product Name',
        fieldName: 'productName',
        required: true,
        inputType: 'text',
    },
    {
        label: 'Product Category',
        fieldName: 'category',
        required: true,
        inputType: 'dropdown',
        options: [
            { value: '', label: '' },
            { value: 'Food', label: 'Food' },
            { value: 'Beverage', label: 'Beverage' },
            { value: 'Wine', label: 'Wine' },
            { value: 'Beer', label: 'Beer' },
        ],
    },
    {
        label: 'Notes',
        fieldName: 'notes',
        required: false,
        inputType: 'text',
    },
];

const UploadSpecs: React.FC = () => {
    const navigate = useNavigate();
    const { triggerFetch } = useUploadContext();

    const [productInfo, setProductInfo] = useState<ProductInfo>({
        productName: '',
        notes: '',
        category: '',
    });
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [showShowcaseModal, setShowShowcaseModal] = useState(false);

    const canSubmit = !!(
        productInfo.productName &&
        productInfo.category &&
        selectedFile
    );
    const handleInputChange = useCallback(
        (field: keyof ProductInfo, value: string) => {
            setProductInfo((prev) => ({
                ...prev,
                [field]: value,
            }));
        },
        [],
    );

    const handleUpload = async () => {
        if (selectedFile) {
            const fileFormat = selectedFile.name
                .split('.')
                .pop()
                ?.toLowerCase();

            if (
                fileFormat &&
                Object.values(FileFormat).includes(fileFormat as FileFormat) &&
                productInfo.category !== ''
            ) {
                const fileFormatEnum = fileFormat as FileFormat;
                const { url, unique_id } = await fetchSpecUploadToken(
                    fileFormatEnum,
                    productInfo.productName,
                );
                triggerFetch();
                await axios.put(url, selectedFile, {
                    headers: { 'x-ms-blob-type': 'BlockBlob' },
                });
                ingestSpec({
                    asset_id: unique_id,
                    file_format: fileFormatEnum,
                    spec_name: productInfo.productName,
                    category: productInfo.category,
                    spec_notes: productInfo.notes,
                });
            }
        }
    };

    return (
        <>
            <ModalBase
                onClose={() => navigate('/specifications')}
                width="500px"
            >
                <div className="upload-components">
                    <h2 className="upload-title">
                        Add New Product Specifications
                    </h2>
                    <p style={{ textAlign: 'center' }}>
                        Upload your specifications document and fill out the
                        required fields.
                    </p>
                    <UploadBoxSpecs
                        setSelectedFile={setSelectedFile}
                        selectedFile={selectedFile}
                    />
                    {inputFields.map((field) => (
                        <div key={field.fieldName}>
                            <InputFieldTitle
                                title={field.label}
                                required={field.required}
                            />
                            {field.inputType === 'text' ? (
                                <input
                                    type="text"
                                    className="user-input-text"
                                    value={productInfo[field.fieldName]}
                                    onChange={(e) =>
                                        handleInputChange(
                                            field.fieldName,
                                            e.target.value,
                                        )
                                    }
                                />
                            ) : field.inputType === 'dropdown' &&
                              field.options ? (
                                <Dropdown
                                    items={field.options}
                                    selectedValue={productInfo[field.fieldName]}
                                    onSelect={(value) =>
                                        handleInputChange(
                                            field.fieldName,
                                            value,
                                        )
                                    }
                                    centered={false}
                                />
                            ) : null}
                        </div>
                    ))}
                    <SignedIn>
                        <button
                            className={'submit-button-upload'}
                            onClick={() => setShowShowcaseModal(true)}
                            disabled={!canSubmit}
                        >
                            Upload
                        </button>
                    </SignedIn>
                    <SignedOut>
                        <SignInButton>
                            <button
                                className={'submit-button-upload'}
                                disabled={!canSubmit}
                            >
                                Upload
                            </button>
                        </SignInButton>
                    </SignedOut>
                </div>
            </ModalBase>
            <ShowcaseModal
                isActive={showShowcaseModal}
                onClose={() => setShowShowcaseModal(false)}
            />
        </>
    );
};

export default UploadSpecs;
